<template>
  <div>
    <NavBar
      style="max-height:50px;margin-bottom: 10px;"
      :username="currentUserName"
      :pageName="pageName"
    ></NavBar>

    <div>
       <v-row justify="center">
          <v-dialog v-model="noPatient" max-width="290">
             <v-card>
                <v-card-title style="word-break:normal">
                   No Available Patient
                </v-card-title>
                <v-card-actions>
                   <v-spacer></v-spacer>
                   <v-btn class="primary" style="background-color: #1467BF;" text @click="closeModal">
                      OK
                   </v-btn>
                </v-card-actions>
             </v-card>
          </v-dialog>
       </v-row>
    </div>
    <v-app>
      <div style="background-color: #f5faff; height: 96vh;">
        <v-row cols="12">
        <v-col cols="2">
        <SideBar style="max-height: 50px;" :pageName="pageName"></SideBar>
        </v-col>
        <v-col cols="10"  style="overflow:scroll; height:90vh">
         <div style="margin: 40px 20px 20px 50px "> 
         <div
              style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              vertical-align: middle;
              "
              >
             <div style="width: 30%" align="left">
                <span style="font-weight: 800; padding-left: 8px;  font-size: 12pt;
                  text-transform: uppercase;   letter-spacing: 1px;">
                  <h3>Create Appointment</h3>
                </span>
              </div>
          </div>
          <!-- <h3
            style=" text-align: left; margin-left: 40px; margin-top: 30px; font-weight: 800; font-size: 12pt; text-transform: uppercase;letter-spacing: 1px;">
              Create Appointment
          </h3> -->
          <v-card
              style="
                width: 95%;
                margin-left: 2px;
                height: 100%;
                margin-top: 20px;
                height: 70vh;
                overflow: scroll;
                overflow-x: hidden;
                padding: 16px 32px;
              "
            >
              <div
                style="display: flex; flex-direction: row; margin: 10px"
                align="left"
              >
                <div
                  style="width: 100%; display: flex; flex-direction: column"
                  align="left"
                >
                  <div style="display: flex; flex-direction: row">
                    <div style="display: flex; margin-bottom: 8%">
                      <!-- <p style="font-size: 8pt">Serach with</p> -->
                      <p></p>
                      <v-select
                        v-model="search_type"
                        :items="items"
                        placeholder="Search With"
                        solo
                        dense
                        @change="nrc_number = '', phoneNumber = '', patient_list = ''"
                        style="width: 25%; margin: 0px; z-index: 10"
                      ></v-select>
                      <div style="margin-left: 2%">
                        <v-text-field
                          v-if="search_type == 'NRC'"
                          v-model="nrc_number"
                          label="Enter NRC number"
                          dense
                          solo
                          style="width: 90%"
                         >
                          <template v-slot:append>
                            <v-btn @click="filterPatient" icon>
                              <img
                                style="width: 25px; height: 25px"
                                src="https://s3iconimages.mymedicine.com.mm/search.svg"
                              />
                            </v-btn>
                          </template>
                        </v-text-field>
                        <v-text-field
                          v-else-if="search_type == 'Phone Number'"
                          v-model="phoneNumber"
                          placeholder="Type here"
                          label="Enter Phone number"
                          :rules="mobileNumberRules"
                          dense
                          solo
                          style="width: 90%"
                          ><template v-slot:append>
                            <v-btn @click="filterPatient" icon>
                              <img
                                style="width: 25px; height: 25px"
                                src="https://s3iconimages.mymedicine.com.mm/search.svg"
                              />
                            </v-btn>
                          </template>
                        </v-text-field>
                      </div>
                    </div>
                  </div>
                  <div v-if="!isLoading">
                  <div v-if="patient_list.length > 0">
                    <div style="display: flex; flex-direction: row;" >
                    <div style="width: 50%" align="left">
                      <p style="font-size: 10pt; font-weight: 600">
                        Patient Name
                      </p>
                    </div>
                    <div style="width: 50%; font-weight: 800" align="center">
                      <h2>  {{ patient_list[0].customer_name }} </h2>
                    </div>
                  </div>
                  <div style="display: flex; flex-direction: row">
                    <div style="width: 50%" align="left">
                      <p
                        style="
                          margin-top: 12px;
                          font-size: 10pt;
                          font-weight: 600;
                        "
                      >
                        Specialization
                      </p>
                    </div>
                    <div style="width: 50%" align="right">
                      <v-select
                        :items="specialist_list"
                        item-value="_id"
                        item-text="specialization_name"
                        label="Specialization"
                        v-model="specialist_name"
                        return-object
                        background-color="#F4F6FA"
                        style="border-radius: 8px"
                        @change="getDoctorList"
                        solo
                      ></v-select>
                    </div>
                  </div>

                  <div style="display: flex; flex-direction: row">
                    <div style="width: 50%" align="left">
                      <p
                        style="
                          margin-top: 12px;
                          font-size: 10pt;
                          font-weight: 600;
                        "
                      >
                        Doctor Name
                      </p>
                    </div>
                    <div style="width: 50%" align="right">
                      <v-select
                        :items="doctor_list"
                        item-text="doctor_name"
                        item-value="_id"
                        label="Doctor Name"
                        v-model="doctor_name"
                        solo
                        return-object
                        background-color="#F4F6FA"
                        style="border-radius: 8px"
                        @change="getDateRange"
                      ></v-select>
                    </div>
                  </div>

                  <div style="display: flex; flex-direction: row">
                    <div style="width: 50%" align="left">
                      <p
                        style="
                          margin-top: 12px;
                          font-size: 10pt;
                          font-weight: 600;
                        "
                      >
                        Date Of Appointment
                      </p>
                    </div>
                    <div style="width: 50%" align="right">
                      <v-select
                        solo
                        :items="dateRange"
                        item-text="display_date"
                         background-color="#F4F6FA"
                        item-value="date_query"
                        item-disabled="slot_disabled"
                        label="Select Date"
                        v-model="selected_date"
                        @change="getTimeSlot"
                      >
                      </v-select>
                    </div>
                  </div>

                  <div style="display: flex; flex-direction: row">
                    <div style="width: 50%">
                      <p
                        style="
                          margin-top: 12px;
                          font-size: 10pt;
                          font-weight: 600;
                        "
                      >
                        Select Time Slot
                      </p>
                    </div>
                    <div style="width: 50%">
                      <v-select
                        :items="time_slot"
                        item-value="_id"
                        item-disabled="customDisabled"
                        label="Time Slot"
                        item-text="displayTime"
                        v-model="time_slot_model"
                        background-color="#F4F6FA"
                        solo
                        style="border-radius: 8px"
                        return-object
                      ></v-select>
                    </div>
                  </div>

                  <div style="display: flex; flex-direction: row">
                    <div style="width: 50%" align="left">
                      <p
                        style="
                          margin-top: 12px;
                          font-size: 10pt;
                          font-weight: 600;
                        ">
                        Description
                      </p>
                    </div>
                    <div style="width: 50%" align="rigth">
                      <v-textarea
                        solo
                        label="Enter description in English"
                        v-model="description"
                          background-color="#F4F6FA"
                        placeholder="Enter more details about the appointment"
                        :rules="[
                          (v) =>
                            (v || '').length < 160 ||
                            'Description must be 160 characters or less',
                        ]"
                      ></v-textarea>
                    </div>
                  </div>


                   <!-- <div style="display: flex; flex-direction: row">
                    <div style="width: 50%" align="left">
                      <p
                        style="
                          margin-top: 12px;
                          font-size: 10pt;
                          font-weight: 600;
                        "
                      >
                        Description (Burmese)
                      </p>
                    </div>
                    <div style="width: 50%" align="rigth">
                      <v-textarea
                        solo
                        label="Enter description in Burmese"
                        v-model="description_mm"
                        placeholder="Enter more details about the appointment"
                        :rules="[
                          (v) =>
                            (v || '').length < 160 ||
                            'Description must be 160 characters or less',
                        ]"
                      ></v-textarea>
                    </div>
                  </div> -->

                  <div style="display: flex; flex-direction: row">
                    <div style="width: 50%">
                      <p
                        style="
                          margin-top: 12px;
                          font-size: 10pt;
                          font-weight: 600;
                        "
                      >
                        Payment Status
                      </p>
                    </div>
                    <div style="width: 50%">
                      <v-select
                        :items="payment_list"
                        v-model="payment_status"
                        label="Select Payment Status "
                        item="payment_status"
                          background-color="#F4F6FA"
                        solo
                      ></v-select>
                    </div>
                  </div>

                  <div align="center">
                    <v-btn
                      style="width: 20vw;background-color: #1467BF;"
                      color="primary"
                      @click="bookAppointment"
                      v-bind:disabled="
                        isBookSlot ||
                        !(
                          description.length < 160 &&
                          specialist_name &&
                          time_slot_model &&
                          doctor_name &&
                          selected_date &&
                          payment_status
                        ) || isCreateAppointmentClicked
                      "
                    >
                      Schedule Appointment
                    </v-btn>
                  </div>
                  </div>
                  </div>
                  <div align="center" v-else>
                    <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                  </div>

                </div>
              </div>
            </v-card>
         </div>
          </v-col>
        </v-row>
      </div>
    </v-app>
  </div>
</template>


<script>
import axios from "axios";
import NavBar from "../../navbar.vue";
import SideBar from "../../../views/sideBar.vue";

export default {
  name: "CustomerHome",
  data() {
    return {
      patient_list: "",
      isFilterClicked:false,
      isBookSlot: false,
      specialist_list: [],
      specialist_name: "",
      doctor_list: [],
      doctor_name: "",
      dateRange: [],
      selected_date: "",
      time_slot: [],
      time_slot_model: "",
      description: "",
      description_mm : "",
      payment_list: ["PAYMENT PENDING", "PAID"],
      payment_status: "",
      currentUser: "",
      currentUserName: "",
      user_id: "",
      phoneNumber: "",
      pageName: "adminCreateAppointment",
      items: ["Phone Number", "NRC"],
      search_type: "",
      nrc_number: "",
      noPatient: false,
      isCreateAppointmentClicked : false,
      mobileNumberRules: [
                        v => v > 0 || 'Mobile number cannot be negative',
                        v => (v.length >= 7 && v.length <=11) || 'Mobile number should be between 7 to 11 digits',
                        ],
      isLoading:false,
      pagination_list:"",

    };
  },
  mounted() {
    this.currentUser = this.$cookies.get("token");
    if (!this.currentUser) {
      this.$router.push({
        name: "AdminLogin",
      });
    } else {
      var data = {
        token : this.currentUser,
        typeOfUser : "ADMIN"
      }
      axios.post(process.env.VUE_APP_BACKEND_URL + "/createAppointmentPage", data)
      .then((successResponse) => {
        this.currentUserName = successResponse.data.admin_data.admin_name;
        this.user_id = successResponse.data.admin_data._id;
        this.specialist_list = successResponse.data.data;
      })
      .catch((errorResponse) => {
        if(errorResponse.response.status == 401) {
          window.alert("Sorry !! You are unauthorized !!");
          this.$router.push({
            name: "AdminLogin",
          });
        } else if (errorResponse.response.status == 400) {
          window.alert(errorResponse);
        }
      });
    }
  },
  components: {
    NavBar,SideBar
  },
  methods: {
    closeModal(){
      this.noPatient = false;
    },
    bookAppointment() {
      this.isCreateAppointmentClicked = true
      this.isBookSlot = true;
      var booking_data = {
        dateOfAppointment: this.selected_date,
        doctorId: this.doctor_name._id,
        timeSlot: this.time_slot_model.time_slot,
        bookedById: this.patient_list[0]._id,
        // bookedForName: this.patient_list[0].customer_name,
        // bookedForId: this.patient_list[0]._id,
        // bookedByName: this.patient_list[0].customer_name,
        // phoneNumber: this.patient_list[0].phoneNumber,
        // NRC: this.patient_list[0].NRC,
        description: this.description,
        paymentStatus: this.payment_status,
        createdById: this.user_id,
        token: this.currentUser,
        typeOfUser: "ADMIN",
      };
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/createAppointmentTwilio",
          booking_data
        )
        .then((getAppointmentDetailsResponse) => {
          if (getAppointmentDetailsResponse.status == 200) {
            window.alert("Booking Successful");
             this.$router.push({
              name: 'AdminHome'
            });
            this.isCreateAppointmentClicked = false
            //this.$router.go();
          }
        })
        .catch((getAppointmentDetailsError) => {
          this.isCreateAppointmentClicked = false;
          if(getAppointmentDetailsError.response.status == 305)
          {
            window.alert("Sorry this slot has been booked");
            this.isBookSlot = false;
          }
          else if (getAppointmentDetailsError.response.status == 401) {
            window.alert("Sorry !! You are unauthorized !!");
            this.$router.push({
              name: "AdminLogin",
            });
          } else {
            window.alert("Something went wrong...");
          }
        });
    },

    getTimeSlot() {
      this.loading = true;
      var date_body = {
        doctorId: this.doctor_name._id,
        dateOfAppointment: this.selected_date,
        token: this.currentUser,
        typeOfUser: "ADMIN",
      };
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/getTimeSlotDisplay",
          date_body
        )
        .then((getTimeSlotResponse) => {
          this.time_slot = getTimeSlotResponse.data.data;
          // var strtoDate = new Date(getTimeSlotResponse.data.data)
          this.time_slot = this.time_slot.map((timeSlot) => ({
            time_slot: timeSlot.time_slot,
            _id: timeSlot._id,
            customDisabled: timeSlot.counter <= 0 ? true : false,
            displayTime: timeSlot.displayTime != '' && timeSlot.displayTime != undefined && timeSlot.displayTime != null ? timeSlot.displayTime : timeSlot.time_slot
          }));
        })
        .catch((getTimeSlotError) => {
          if (getTimeSlotError.response.status == 401) {
            this.auth_error_enable = true;
          } else {
            this.error_enable = true;
          }
        });
      this.loading = false;
    },

    getDateRange() {
      this.dateRange = [];
      this.selected_date = "";
      var postData = {
        token: this.currentUser,
        typeOfUser: "ADMIN",
        doctorId: this.doctor_name._id,
      };
      axios
        .post(process.env.VUE_APP_BACKEND_URL + "/getDateDisplay", postData)
        .then((getDateRangeResponse) => {
          for (var i = 0; i < getDateRangeResponse.data.data.length; i++) {
            var strtoDate = new Date(
              getDateRangeResponse.data.dataConverted[i]
            );
            this.dateRange.push({
              display_date: getDateRangeResponse.data.data[i],
              date_query: getDateRangeResponse.data.dataConverted[i],
              slot_disabled:
                getDateRangeResponse.data.buttonshow[i].isDateSlotPresent ==
                "YES"
                  ? false
                  : true,
              day: strtoDate.toLocaleString("en-us", {
                weekday: "short",
              }),
            });
          }
        })
        .catch((getDateRangeError) => {
          if (getDateRangeError.response.status == 401) {
            this.auth_error_enable = true;
          } else {
            this.error_enable = true;
          }
        });
    },
    getDoctorList() {
      var doctorData = {
        token: this.currentUser,
        typeOfUser: "ADMIN",
        specialization: this.specialist_name._id,
      };
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/getDoctorsBySpecialization",
          doctorData
        )
        .then((specialistDataListResponse) => {
          this.doctor_list = specialistDataListResponse.data.data;
        })
        .catch((specialistDataListError) => {
        });
    },
    closeDialogUnauthorized() {
      this.auth_error_enable = false;
      this.$router.push({
        name: "Login",
      });
    },
    filterPatient() {
      this.isLoading = true;
      if(this.nrc_number != '' || this.phoneNumber != ''){
      this.patient_list = "";
      var getPatientData = {
        token: this.currentUser,
        typeOfUser: "ADMIN",
        page: 1,
        limit: 1,
        NRC : this.nrc_number,
        phoneNumber: this.phoneNumber,
      };

      axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/readAllCustomers",
          getPatientData
        )
        .then((getparticularListResponse) => {
          this.patient_list = getparticularListResponse.data.data;
          this.isLoading = false
          if(this.patient_list.length <= 0){
            this.isLoading = false
            this.noPatient = true;
          }
            this.isFilterClicked = true;
        })
        .catch((getparticularListError) => {
          this.isLoading = false
          if (getparticularListError.response.status == 401) {
            window.alert("Sorry !! You are unauthorized !!");
            this.$router.push({
              name: "AdminLogin",
            });
          } else {
            this.isLoading = false
            window.alert("Something went wrong...");
          }
        });
      }
      else{
        window.alert("Phone Number and NRC can't be empty");
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
td,
th {
  border :0px;
  padding: 10px;
}
.fb-customerchat{
  display: none;
}
</style>
